.modal.sidenav-modal {
  .modal-dialog {
    right: 0;
    width: 300px;
    top: 0;
    height: 100%;
    max-height: 100%;
    bottom: 0;
    transform: translate(100%, 0);

    .modal-content {
      height: 100%;
      border-radius: 0;
      background-color: $dark;
    }
  }
  &.show .modal-dialog {
    transform: none;
  }
}

.sidenav-icon {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: $gray-500;
}
