@font-face {
  font-family: "SF Pro Display";
  font-weight: bold;
  src: url("../assets/fonts/SF-Pro-Display-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../assets/fonts/SF-Pro-Display-Regular.otf") format("opentype");
}
