.pincode-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .pincode-input-text {
    background-color: $gray-800 !important;
    border-radius: $input-border-radius;
    border: 1px solid $gray-800 !important;
    height: 56px !important;
    flex: 1;
    margin: 0 8px !important;
    color: $white;
    font-size: 42px;
    line-height: 1.5;

    &:focus {
      border-color: $white !important;
      box-shadow: none !important;
    }

    &:first-child {
      margin-left: 0 !important;
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.invalid-pin {
  .pincode-input-text:focus,
  .pincode-input-text {
    border-color: $red !important;
  }
}
