ul.security {
  padding: 0;
  margin: 0;

  li {
    list-style: none;

    img {
      margin-right: 8px;
      margin-bottom: 2px;
    }
  }
}


@include media-breakpoint-between(sm, lg) {
  ul.security {
    min-height: 142px;
    padding: 12px;
    background: $secondary-bg;
    border-radius: 8px;
  }
}

@include media-breakpoint-only(xs) {
  ul.security {
    padding: 12px;
    background: $secondary-bg;
    border-radius: 8px;
    column-count: 2;
  }
}