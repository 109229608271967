.anomality {
  gap: 14px;
}


@include media-breakpoint-between(sm, lg) {
  .anomaly {
    height: 42px;
  }
}

@include media-breakpoint-only(xs) {
  .anomaly {
    height: 40px;
  }
}
