.token {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.24px;

  img {
    margin-left: 4px;
  }
}


.border-name {
  border-bottom: 1px solid var(--separator-color-with-transparency, rgba(84, 84, 88, 0.30));
}


@include media-breakpoint-between(sm, lg) { // sm, md
  .btn-snipe {
    width: 110px;
  }
}

@include media-breakpoint-only(xs) {
  .btn-snipe {
    width: 100px;
  }

  .border-name {
    border-bottom: 0px;
  }

}