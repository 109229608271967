@include media-breakpoint-between(sm, lg) {
  .liq {
    height: 47px;
  }

  .liquidity {
    .name {
      font-weight: 400;
      color: $secondary;
    }
  }
}
