.metrics {
  width: 154px;

  .text-secondary {
    font-size: 11px;
  }
}


@include media-breakpoint-between(xs, lg) {
  .metrics {
    width: auto;
    margin-top: 16px;
  }

  .metrics .metric {
    padding: 8px 8px 8px 12px;
    height: 69px;
    background: $secondary-bg;
  }


}

@include media-breakpoint-only(xs) {

  .metrics .metric {

    height: auto

  }
}
