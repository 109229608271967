#main-layout {
  > div:first-child,
  #assets-card,
  .full-page {
    padding-bottom: 50px;
  }
}

#bottom-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: 55px;
  background-color: #2C2C2C;
  padding: 3px 0 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1013;
  border-top: 1px solid rgba(#545458, .65);

  &.with-notch {
    padding-bottom: 30px;
    height: 85px;
    transition: all .3s ease;
    &.minimize {
      padding-bottom: 0;
      height: 55px;
    }
  }

  .btn.btn-link {
    &.tx-white:focus,
    &.tx-white:active,
    &.tx-white:hover {
      color: #fff;
    }
    &.tx-muted:focus,
    &.tx-muted:active,
    &.tx-muted:hover {
      color: $text-muted;
    }
  }
}
