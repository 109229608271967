
.change-minus {
  font-size:11px;
  line-height: 13px;
  border-radius: 6px;
  background: rgba(255, 69, 58, 0.15);
  padding: 6px 8px;
  color: $red;
  display: inline-block;
  letter-spacing: 0.066px;
}

.change-plus {
  font-size:11px;
  line-height: 13px;
  border-radius: 6px;
  background: rgba(50, 215, 75, 0.15);
  padding: 6px 8px;
  color: $green;
  display: inline-block;
  letter-spacing: 0.066px;
}



@include media-breakpoint-between(sm, lg){
  .change-minus, .change-plus {
    display: block;
  }

  .change {
    margin-top: 16px;

    .columns {
      background: $secondary-bg;
      padding: 12px;
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-only(xs){
  .change-minus, .change-plus {
    display: block;
  }

  .change {
    margin-top: 16px;
  }
}

